$(document).ready(function () {
    $("#validstudent").validate({
        rules: {
            student_number: {required: true},
        },
        submitHandler: function (form) {

            var button = $(form).find('[type="submit"]');

            $.ajax({
                url: '/api/v1/IValidate/ValidStudent',
                type: 'POST',
                data: $(form).serialize(),
                dataType: 'JSON',
                beforeSend: function () {
                    button.attr('disabled', 'disabled');
                    button.attr('orig-html', button.html());
                    button.html('Loading...');
                },
                success: function (returndata) {
                    $(form).find('#result code').html(JSON.stringify(returndata, null, 2));
                    $(form).find('#result').show();
                },
                error: function () {
                    $(form).find('#result code').html("An error occurred while trying to connect to the Student API. Our VPN to the network may be offline or under maintenance.");
                    $(form).find('#result').show();
                },
                complete: function () {
                    location.href = '#result';
                    button.html(button.attr('orig-html'));
                    button.removeAttr('disabled');
                }});
        }
    });

    $("#cantransact").validate({
        rules: {
            student_number: {required: true},
        },
        submitHandler: function (form) {

            var button = $(form).find('[type="submit"]');

            $.ajax({
                url: '/api/v1/IValidate/CanTransact',
                type: 'POST',
                data: $(form).serialize(),
                dataType: 'JSON',
                beforeSend: function () {
                    button.attr('disabled', 'disabled');
                    button.attr('orig-html', button.html());
                    button.html('Loading...');
                },
                success: function (returndata) {
                    $(form).find('#result code').html(JSON.stringify(returndata, null, 2));
                    $(form).find('#result').show();
                },
                error: function () {
                    $(form).find('#result code').html("An error occurred while trying to connect to the Student API. Our VPN to the network may be offline or under maintenance.");
                    $(form).find('#result').show();
                },
                complete: function () {
                    location.href = '#result';
                    button.html(button.attr('orig-html'));
                    button.removeAttr('disabled');
                }});
        }
    });

    $("#requesttransaction").validate({
        rules: {
            student_number: {required: true},
            amount: { required: true },
            trans_type: { required: true },
            trans_desc_eng: { required: true },
            trans_desc_afr: { required: true }
        },
        submitHandler: function (form) {

            var button = $(form).find('[type="submit"]');

            $.ajax({
                url: '/api/v1/ITransAct/RequestTransaction',
                type: 'POST',
                data: $(form).serialize(),
                dataType: 'JSON',
                beforeSend: function () {
                    button.attr('disabled', 'disabled');
                    button.attr('orig-html', button.html());
                    button.html('Loading...');
                },
                success: function (returndata) {
                    $(form).find('#result code').html(JSON.stringify(returndata, null, 2));
                    $(form).find('#result').show();
                },
                error: function () {
                    $(form).find('#result code').html("An error occurred while trying to connect to the Student API. Our VPN to the network may be offline or under maintenance.");
                    $(form).find('#result').show();
                },
                complete: function () {
                    location.href = '#result';
                    button.html(button.attr('orig-html'));
                    button.removeAttr('disabled');
                }});
        get}
    });

        $("#getlogs").validate({
        submitHandler: function (form) {

            var button = $(form).find('[type="submit"]');

            $.ajax({
                url: '/api/v1/ILog/RequestTransactionLogs',
                type: 'GET',
                data: $(form).serialize(),
                dataType: 'JSON',
                beforeSend: function () {
                    button.attr('disabled', 'disabled');
                    button.attr('orig-html', button.html());
                    button.html('Loading...');
                },
                success: function (returndata) {
                    $(form).find('#result code').html(JSON.stringify(returndata, null, 2));
                    $(form).find('#result').show();
                },
                error: function () {
                    $(form).find('#result code').html("An error occurred while trying to connect to the Student API. Our VPN to the network may be offline or under maintenance.");
                    $(form).find('#result').show();
                },
                complete: function () {
                    location.href = '#result';
                    button.html(button.attr('orig-html'));
                    button.removeAttr('disabled');
                }});
        }
    });
});
